@import "./../../color/color.scss";

.meetTheTeamSection {
  background-color: $section-background;
  padding-block: 2.5rem;
  // margin-bottom: 5rem;
  .meetTheTeamContent {
    @include default-width;
    .meetTheTeamSliderDiv {
      position: relative;

      .buttonSlider {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 50%;
        .arrowSliderImage {
          width: 2.5rem;
          height: 2.5rem;
          cursor: pointer;
        }
      }
      .rightSlider {
        left: -60px;
        transform: translate(0, -50%);
      }
      .leftSlider {
        right: -60px;
        transform: translate(0, -50%) rotate(180deg);
      }
      .teamDiv {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        display: flex;
        gap: 1.25rem;
        margin-top: 2.5rem;
        /* width */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .buttonSlider {
    display: none;
  }
  .meetTheTeamSection {
    // margin-bottom: 2.5rem;
    .meetTheTeamContent {
      .teamDiv {
        gap: 1rem;
      }
    }
  }
}

// .teamGallery {
//   display: flex;
//   align-items: center;
//   overflow: hidden;
// }

// .teamDiv {
//   display: flex;
//   transition: transform 0.3s;
// }

// .teamDiv .TeamCard {
//   /* Add your card styling here */
//   /* Example: width: 250px; margin: 10px; */
// }

// .prevBtn,
// .nextBtn {
//   background-color: #ccc;
//   padding: 10px 20px;
//   cursor: pointer;
// }

// .prevBtn:hover,
// .nextBtn:hover {
//   background-color: #bbb;
// }

@import "./../color/color.scss";

.contactUs {
  .contactUsSection {
    @include default-width;
    padding-block: 5rem;
    .mapStyles {
      border: none;
      width: 100%;
      height: 30.75rem;
      border-radius: 8px;
      margin-bottom: 2.5rem;
    }
    .contactUsText {
      @include text-style;
      margin-bottom: 2.5rem;
    }
    .contactUsInfoDiv {
      display: flex;
      gap: 3.875rem;
      align-items: center;
      .contactUsDiv {
        flex: 1 1 0;

        .contactSubDiv {
          margin-bottom: 1.25rem;
          .contactSubHeader {
            font-weight: 600;
            font-size: 1rem;
            color: $secondary-color;
            margin-bottom: 0.5rem;
          }
          .contactInfoDiv {
            display: flex;
            align-items: center;
            gap: 2rem;
            .contactInfoSubHeader {
              width: 10rem;
              margin-left: 1.25rem;
              font-weight: 500;
              font-size: 1rem;
              color: $secondary-color;
            }
            .contactInfo {
              @include text-style;
            }
          }
        }
      }
      .contactUsInfoImg {
        border-radius: 8px;
        width: 50%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.addressDiv {
  margin-bottom: 1.25rem;
  width: 100%;

  .addressHeader {
    width: 40%;
    font-weight: 600;
    color: $secondary-color;
    font-size: 1rem;
    vertical-align: top;
  }
  .address {
    @include text-style;
  }
}

@media (max-width: 600px) {
  .contactUs {
    .contactUsSection {
      @include default-width;
      margin-block: 2.5rem;
      .mapStyles {
        height: 10.75rem;
        margin-bottom: 1rem;
      }
      .contactUsText {
        margin-bottom: 1rem;
      }
      .contactUsInfoDiv {
        flex-direction: column;
        .contactUsDiv {
          width: 100%;

          .contactSubDiv {
            margin-bottom: 1rem;
            .contactSubHeader {
              font-size: 0.875rem;
            }
            .contactInfoDiv {
              .contactInfoSubHeader {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
      .contactUsInfoImg {
        display: none;
      }
    }
  }
  .addressDiv {
    margin-bottom: 1rem;
    .addressHeader {
      font-size: 0.875rem;
    }
  }
}

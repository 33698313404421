* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
p,
h1,
h2,
h3,
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 125%;
}
p {
  line-height: 150%;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

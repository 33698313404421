@import "./../../color/color.scss";

.overView {
  display: flex;
  gap: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid $primary-color;
  .overViewIcon {
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
  }
  .overViewText {
    .overViewHeader {
      color: $secondary-color;
      margin-bottom: 0.25rem;
      font-weight: 600;
      font-size: 1.25rem;
    }
    .overViewPara {
      @include text-style;
    }
  }
}

@media (max-width: 600px) {
  .overView {
    padding-bottom: 1rem;
    .overViewIcon {
      width: 3rem;
      height: 3rem;
    }
    .overViewText {
      .overViewHeader {
        font-size: 1rem;
      }
      .overViewPara {
        @include text-style;
      }
    }
  }
}

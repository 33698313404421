@import "./../../color/color.scss";
.headerDiv {
  padding-left: 0.75rem;
  border-left: 4px solid $primary-color;
  .header {
    font-size: 2rem;
    color: $secondary-color;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .footerHeader {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 600px) {
  .headerDiv {
    padding-left: 0.65rem;
    .header {
      font-size: 1.25rem;
    }
    .footerHeader {
      font-size: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }
}

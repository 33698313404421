@import "./../../color/color.scss";

.subDivisionSection {
  background-color: $section-background;
  padding-block: 2.5rem;

  .subDivisionContent {
    @include default-width;
    display: flex;
    align-items: center;
    gap: 2.5rem;
    .textContent {
      flex: 1 1 0;
      .textContentPara {
        @include text-style;
        margin-bottom: 1.25rem;
      }
    }
    .subContentImgDiv {
      flex: 1 1 0;
      .subImg {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }
  }
}

.subDivisionSectionWhite {
  background-color: #fff;
  padding-block: 0;
  .subDivisionContentReverse {
    flex-direction: row-reverse;
  }
}

@media (max-width: 600px) {
  .subDivisionSection {
    .subDivisionContent {
      flex-direction: column;
      .textContent {
        .textContentPara {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

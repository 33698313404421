$primary-color: #ff2a0a;
$secondary-color: #02121f;
$text-color: #02121f99;
$section-background: #f8f8f8;
$white-color: #fff;
$border-color: #23303533;

@mixin default-width {
  max-width: 80rem;
  padding: 0 4rem;
  margin-inline: auto;
  @media (max-width: 600px) {
    padding: 0 1.5rem;
  }
}

@mixin text-style {
  font-weight: 500;
  color: $text-color;
  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
}

@mixin default-spacing-between-sections {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  @media (max-width: 600px) {
    gap: 2.5rem;
  }
}

@import "./../../color/color.scss";

.teamCard {
  text-align: center;
  scroll-snap-align: center;
  .teamImg {
    border-radius: 4px;
    // width: 14rem;
    height: 17rem;
    object-fit: fill;
    margin-bottom: 1.25rem;
  }
  .teamName {
    color: $secondary-color;
    font-size: 1.25rem;
    font-weight: 600;
  }
  .teamDesignation {
    @include text-style;
  }
}

@media (max-width: 600px) {
  .teamCard {
    .teamImg {
      // width: 10rem;
      height: 13rem;
    }
    .teamName {
      font-size: 1rem;
    }
  }
}

@import "./../../color/color.scss";
.matrixCard {
  flex: 1 1 0;
  text-align: center;
  .matrixIcon {
    width: 7.5rem;
  }
  .matrixHeader {
    color: $secondary-color;
    font-size: 3rem;
    font-weight: 700;
    span {
      color: $primary-color;
    }
  }
  .matrixSubHeader {
    font-size: 1.125rem;
    color: $text-color;
    font-weight: 500;
  }
}

@media (max-width: 600px) {
  .matrixCard {
    .matrixIcon {
      width: 4rem;
    }
    .matrixHeader {
      font-size: 1.25rem;
    }
    .matrixSubHeader {
      font-size: 0.75rem;
    }
  }
}

@import "./../../color/color.scss";

.productCard {
  display: inline-block;
  position: relative;
  border-radius: 4px;
  border: 0.5px solid $border-color;
  // width: 17.5rem;
  .productImg {
    display: block;
    width: 100%;
    height: 280px;
    // aspect-ratio: 1/3;
    border-radius: 4px 4px 0 0;
  }
  .productContentDiv {
    // position: absolute;
    width: 100%;
    padding: 0.75rem;
    background: #ffffff;
    border-radius: 0 0 4px 4px;
    bottom: 0;
    .productName {
      font-size: 1.25rem;
      font-weight: 600;
      color: $secondary-color;
    }
    .productLink {
      color: $primary-color;
      font-size: 0.875rem;
    }
  }
}

@import "./../../color/color.scss";

.primaryBtn {
  border: none;
  border-radius: 4px;
  background-color: $primary-color;
  color: #fff;
  padding: 0.5rem 2rem;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 1.125rem;
}

.primaryBtnLarge {
  padding: 0.7rem 5rem;
}

@media (max-width: 600px) {
  .primaryBtn {
    padding: 0.4rem 1.5rem;
    font-size: 1rem;
  }

  .primaryBtnLarge {
    padding: 0.5rem 2rem;
  }
}

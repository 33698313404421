@import "./../../color/color.scss";

.timeline {
  @include default-width;
  width: 100%;
}

@media (max-width: 600px) {
  .timeline {
    display: none;
  }
}

@import "./../color/color.scss";

.footer {
  background-color: $secondary-color;
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  .footerContent {
    @include default-width;
    .companyDetails {
      display: flex;
      gap: 6rem;
      .aboutUsDiv,
      .companyLinkDiv,
      .contactUsDiv {
        flex: 1 1 0;
      }
      .aboutUsDiv {
        .logo {
          margin-bottom: 0.5rem;
        }
        .componyInfo {
          color: #ffffff99;
          font-weight: 500;
        }
      }
      .companyLinkDiv {
        .linkDiv {
          display: flex;
          gap: 6rem;
          div {
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
            a {
              color: #fff;
              font-weight: 500;
            }
          }
        }
      }
      .contactUsDiv {
        .addressDiv {
          display: flex;
          gap: 4rem;
          margin-bottom: 1.25rem;
          .addressHeader {
            font-weight: 600;
            color: #fff;
            font-size: 1rem;
          }
          .address {
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .contactSubDiv {
          margin-bottom: 1.25rem;
          .contactSubHeader {
            font-weight: 600;
            font-size: 1rem;
            color: #fff;
            margin-bottom: 0.5rem;
          }
          .contactInfoDiv {
            display: flex;
            align-items: center;
            gap: 2rem;
            .contactInfoSubHeader {
              width: 5rem;
              margin-left: 1.25rem;
              font-weight: 400;
              font-size: 1rem;
              color: #fff;
            }
            .contactInfo {
              color: rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
    }
    .subFooter {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 2px solid rgba(255, 255, 255, 0.6);
      .copyright {
        font-weight: 500;
        color: #fff;
      }
      .socialMeidaDiv {
        display: flex;
        gap: 1rem;
        .socialMediaIcons {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .footer {
    padding-top: 2.5rem;
    padding-bottom: 2rem;
    .footerContent {
      .companyDetails {
        flex-direction: column;
        gap: 3rem;
        .aboutUsDiv {
          .componyInfo {
            font-size: 0.875rem;
          }
        }
        .companyLinkDiv {
          .linkDiv {
            div {
              gap: 1.5rem;
              a {
                font-size: 0.875rem;
              }
            }
          }
        }
        .contactUsDiv {
          .addressDiv {
            gap: 4rem;
            .addressHeader {
              font-size: 0.875rem;
            }
            .address {
              font-size: 0.875rem;
            }
          }
          .contactSubDiv {
            .contactSubHeader {
              font-size: 0.875rem;
            }
            .contactInfoDiv {
              display: flex;
              align-items: center;
              gap: 2rem;
              .contactInfoSubHeader {
                font-size: 0.875rem;
              }
              .contactInfo {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
      .subFooter {
        .copyright {
          font-size: 0.875rem;
        }
        .socialMeidaDiv {
          a {
            img {
              width: 1.25rem;
            }
          }
          .socialMediaIcons {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}

@import "./../../color/color.scss";

.industryCard {
  background-color: $section-background;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-block: 2.5rem;
  gap: 1rem;
  align-items: center;
  .industryIcon {
    width: 3.125rem;
    height: 3.125rem;
  }
  .industryHeader {
    color: $secondary-color;
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .industryCard {
    gap: 0.5rem;
    padding: 1rem 0.5rem;
    .industryIcon {
      width: 2.5rem;
      height: 2.5rem;
    }
    .industryHeader {
      font-size: 0.675rem;
    }
  }
}

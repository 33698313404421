@import "./../../color/color.scss";
.bigTextSectionDiv {
  padding-block: 2.5rem;
  margin-bottom: 5rem;
  .bigText {
    @include default-width;
    color: $secondary-color;
    text-align: center;
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 150%;
    span {
      color: $primary-color;
      font-weight: 700;
    }
  }
}

@media (max-width: 600px) {
  .bigTextSectionDiv {
    .bigText {
      font-size: 1.5rem;
    }
  }
}

@import "./../../color/color.scss";
.pageImgSection {
  width: 100%;
  height: 65dvh;
  position: relative;

  .pageImgHeader {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 2.625rem;
    color: $white-color;
    font-weight: 700;
    z-index: 3;
  }
  .headerCarouselContentDiv {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    padding: 2rem 4rem 4rem;
    text-align: center;
    transform: translate(-50%, 0);
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;

    .carouselHeader {
      font-size: 2.625rem;
      margin-bottom: 0.75rem;
      color: #fff;
      font-weight: 600;
    }
    .carouselSubHeader {
      font-size: 1.125rem;
      margin-bottom: 2.5rem;
      color: #ffffff;
      width: 80%;
      margin-inline: auto;
    }
  }
}

.carouselImg {
  width: 100%;
  height: 65dvh;
  object-fit: cover;
}

.heroCarousel {
  height: 100dvh;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.overlay-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;

  left: 0;
}
@media (max-width: 600px) {
  .pageImgSection {
    height: 35dvh;
    .pageImgHeader {
      font-size: 1.5rem;
      bottom: 1.5rem;
    }
    .headerCarouselContentDiv {
      width: 100%;
      padding: 2rem 2rem 3rem;

      .carouselHeader {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .carouselSubHeader {
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }
  }
  .carouselImg {
    height: 35dvh;
  }
  .heroCarousel {
    height: 100dvh;
  }
  .overlay-bottom {
    height: 30%;
  }
}

@import "./../../color/color.scss";

.directorSection {
  background-color: $section-background;
  padding-block: 5rem;
  .directorSectionContentDiv {
    @include default-width;
    display: flex;
    gap: 2.5rem;
    align-items: flex-start;
    .managerImg {
      width: 30.625rem;
      border-radius: 4px;
    }
    .directorText {
      color: $text-color;
      font-weight: 500;
    }
    .directorDetails {
      font-weight: 500;
      color: #02121fcc;
      text-align: right;
    }
  }
}

@media (max-width: 600px) {
  .directorSection {
    padding-block: 2.5rem;
    .directorSectionContentDiv {
      flex-direction: column;
      .managerImg {
        width: 100%;
        gap: 1.5rem;
      }
      .directorText {
        font-size: 0.875rem;
      }
      .directorDetails {
        font-size: 0.875rem;
      }
    }
  }
}

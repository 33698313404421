@import "./../../color/color.scss";

.industriesWeServeSection {
  @include default-width;
  .industriesPararaph {
    @include text-style;
    margin-bottom: 2.5rem;
  }
  .industriesGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 600px) {
  .industriesWeServeSection {
    .industriesGrid {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }
  }
}

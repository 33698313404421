@import "./../color/color.scss";

.navigationBar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;
  margin-inline: auto;
  background-color: $white-color;
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
  .logo {
    width: 13rem;
  }
  .navLinks {
    display: flex;
    gap: 2.5rem;
    .navLink {
      position: relative;

      a {
        @include text-style;
      }
      .linkActive {
        color: $primary-color;
        font-weight: 600;
      }
      .productDropdown {
        width: 20rem;
        background: $white-color;
        border-radius: 4px;
        position: absolute;
        top: 220%;
        .productSubLink {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $border-color;
          padding: 0.75rem 1rem;
          position: relative;
          &:hover {
            background-color: $section-background;
          }
          p {
            color: $text-color;
            font-weight: 500;
          }
          .productSingleLinks {
            display: none;
            width: 15.625rem;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 4px;
            transform: translate(99%);
            background-color: $white-color;
            .productSingleLink {
              color: $text-color;
              font-weight: 500;
              padding: 0.75rem 1rem;
              border-bottom: 1px solid $border-color;
              &:hover {
                background-color: $section-background;
              }
            }
          }
          &:hover .productSingleLinks {
            display: block;
          }
        }
      }
    }
    .close {
      display: none;
    }
  }
  .hamburger {
    display: none;
  }
}

@media (max-width: 600px) {
  .navigationBar {
    padding: 1rem 1.5rem;
    .logo {
      width: 10rem;
    }
    .navLinks {
      position: fixed;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%;
      height: 100vh;
      background-color: $white-color;
      top: 0;
      left: 0;
      transform: translate(100%);
      transition: transform 0.2s linear;
      .close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        display: block;
      }
    }
    .navLinksActive {
      transform: translate(0);
    }
    .hamburger {
      width: 1.5rem;
      display: block;
    }
  }
}

@import "./../color/color.scss";

.productGrid {
  width: 80%;
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}
.noProductFound {
  text-align: center;
  padding-bottom: 4rem;
}
.productBackDiv {
  text-align: right;
  width: 80%;
  margin-inline: auto;
  margin-bottom: 5rem;
}
@media (max-width: 600px) {
  .productGrid {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
  .noProductFound {
    padding-bottom: 4rem;
  }
  .productBackDiv {
    margin-bottom: 3rem;
  }
}

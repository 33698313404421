@import "./../../color/color.scss";
.searchBarDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .searchBarSubDiv {
    position: relative;
    width: 40%;

    .searchBar {
      font-family: "Poppins", sans-serif;
      width: 100%;
      padding: 1rem 4rem 1rem 3.5rem;
      border-radius: 4px;
      border: 1px solid $border-color;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-style: italic;
      }
    }
    .searchBarIcon,
    .filterDiv {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    .searchBarIcon {
      width: 1.75rem;
      left: 1rem;
    }
    .filterDiv {
      right: 0;
      padding: 1rem 0 1rem 0;
      cursor: pointer;
      .filterButton {
        @include text-style;
        font-family: "Poppins", sans-serif;
        font-size: 1rem;
        background-color: transparent;
        border: none;
        padding: 0 1.5rem;
        border-left: 1px solid $border-color;
      }
      .filterDropdownIcon {
        vertical-align: middle;
        margin-left: 1rem;
      }
      .filterOptions {
        display: none;
        padding: 1rem;
        position: absolute;
        top: 97%;
        width: 16.875rem;
        border-radius: 4px;
        border: 0.5px solid $border-color;
        background-color: $white-color;
        .filterOption {
          @include text-style;
          font-size: 0.875rem;
          font-weight: 400;
          padding-bottom: 0.75rem;
          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
          border-bottom: 0.5px solid $border-color;
        }
      }
      &:hover .filterOptions {
        display: block;
      }
    }
  }
}

@media (max-width: 600px) {
  .searchBarDiv {
    .searchBarSubDiv {
      width: 90%;

      .searchBar {
        padding: 0.8rem 4rem 0.8rem 3.5rem;
      }

      .searchBarIcon {
        width: 1.5rem;
      }
      .filterDiv {
        padding: 1rem 0 1rem 0;
        .filterButton {
          padding: 0 1rem;
        }
        .filterDropdownIcon {
          width: 1rem;
        }
        .filterOptions {
          left: -180%;
        }
      }
    }
  }
}

@import "./../../color/color.scss";

.whatWeDoSection {
  @include default-width;
  .paragraph {
    color: $text-color;
    font-weight: 500;
    margin-bottom: 3.25rem;
  }
  .cardDiv {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 5rem;
  }
  .matrixDiv {
    width: 70%;
    gap: 5rem;
    margin-inline: auto;
    justify-content: space-between;
    display: flex;
  }
}
@media (max-width: 600px) {
  .whatWeDoSection {
    .paragraph {
      font-size: 0.875rem;
      margin-bottom: 2rem;
    }
    .cardDiv {
      flex-direction: column;
      gap: 2.5rem;
      margin-bottom: 2.5rem;
    }
    .matrixDiv {
      width: 100%;
      gap: 1.5rem;
    }
  }
}

@import "./../../color/color.scss";

.inquireButton {
  background-color: $primary-color;
  display: inline-block;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  gap: 0.25rem;
  .inquireText {
    font-size: 0.875rem;
    color: #fff;
    font-weight: 600;
  }
  .inquireIcon {
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 600px) {
  .inquireButton {
    padding: 0.4rem 0.8rem;
    .inquireText {
      font-size: 0.75rem;
    }
    .inquireIcon {
      width: 20px;
      height: 20px;
    }
  }
}

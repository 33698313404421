@import "./../color/color.scss";

.product {
  @include default-spacing-between-sections;

  .productDetails {
    @include default-width;
    padding: 8rem 5rem;
    .currentProductDetail {
      @include text-style;
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 2.5rem;
      a {
        @include text-style;
      }
      span {
        color: $secondary-color;
      }
    }
    .productSubDiv {
      display: flex;
      gap: 4rem;
      .productImage {
        align-self: flex-start;
        width: 40%;
        border-radius: 4px;
        object-fit: contain;
      }
      .productTextContent {
        .productDescription {
          @include text-style;
          margin-bottom: 2.5rem;
          white-space: pre-line;
        }

        .productSubDiv {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-bottom: 1.5rem;
          .productSubHeader {
            font-size: 1rem;
            font-weight: 600;
          }
          .productSubPara {
            font-size: 0.875rem;
            color: $text-color;
            white-space: pre-line;
          }
        }
      }
    }
    .tableMainDiv {
      .tableDiv {
        .tableHeaderHeader {
          margin-bottom: 1rem;
          font-size: 1rem;
          font-weight: 600;
        }
        .tableImage {
          width: 100%;
        }
      }
    }
    .backDiv {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: right;
      a {
        text-decoration: underline;
        font-weight: 600;
        color: $secondary-color;
      }
    }
  }
}

@media (max-width: 600px) {
  .product {
    padding: 6rem 0;
    .productDetails {
      .productSubDiv {
        flex-direction: column;
        gap: 2rem;
        .productImage {
          width: 100%;
        }
      }
    }
  }
}

@import "./../../color/color.scss";

.companyOverViewSection {
  background-color: $section-background;
  padding-block: 2.5rem;
  .companyOverViewContent {
    @include default-width;
    .companyOverView {
      margin-top: 2.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.5rem;
    }
  }
}

@media (max-width: 600px) {
  .companyOverViewSection {
    padding-block: 2.5rem;
    .companyOverViewContent {
      .companyOverView {
        grid-template-columns: 1fr;
        gap: 2rem;
      }
    }
  }
}

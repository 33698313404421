@import "./../../color/color.scss";
.whoWeAreSection {
  @include default-width;
  .whoWeAreText {
    @include text-style;
    margin-bottom: 2.5rem;
  }
  .aboutUsImg {
    width: 100%;
    border-radius: 8px;
  }
}

@import "./../../color/color.scss";

.divisionCard {
  flex: 1 1 0;
  padding: 1rem;
  .cardImg {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 0.5rem;
  }
  .cardHeader {
    color: $secondary-color;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
  }
  .cardText {
    color: $text-color;
    margin-bottom: 1.25rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 600px) {
  .divisionCard {
    padding: 0;
    .cardHeader {
      font-size: 1.125rem;
    }
    .cardText {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  }
}
